import './App.scss';
import { Button, Card, Col, Row, Typography } from 'antd';
const carbonFootprint = 95.911;

function App() {
  return (
    <div className="app">
      <div className='app-content' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/background.png)` }}>
        <Row align={'middle'}>
          <Col span={10} offset={1}>
            <div className='app-content-title'>Discover Your Carbon Footprint</div>
            <span className='app-content-subtitle'>Take the first step towards <span style={{ color: '#05B012' }}>Greener Future</span></span>
          </Col>
          <Col span={8} offset={3}>
            <Card className='app-content-carbon' title="Calculate your estimated carbon footprint here" bordered={false}>
              <div>Total Carbon Footprint</div>
              <Typography.Title><span className='app-content-carbon-value'>{carbonFootprint}</span> KgCO<sup>2</sup></Typography.Title>
              <Button type="primary" block>View Carbon Invoice</Button>
            </Card>
            <div className='app-content-footnote'>
              <div>*) Based on data of all Kargo shipments originated from Jabodetabek area only</div>
              <div>**) The result is an estimation, there’s will some margin error</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default App;
